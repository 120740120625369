import { createRouter, createWebHistory } from "vue-router";
import { ReviseMeta } from '@/util/meta';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // 入口页面
    {
      path: "/",
      component: () => import("./views/yy/index.vue"),
      meta: {
        title: '《斗破苍穹：异火降世》预约官网-斗破苍穹正版飞空炫技手游',
        keywords: '斗破苍穹、萧炎、萧薰儿、海波东、巅峰对决、美杜莎、斗帝、斗气、斗破苍穹预约、斗破苍穹手游、斗破苍穹官网、斗破苍穹、斗破苍穹手游、古族、职业、玄幻、土豆、天蚕土豆、斗破苍穹手游、斗破手游、斗气之路、斗破苍穹、斗气之路手游、斗破苍穹手游版、斗破苍穹游戏、斗破苍穹ol、斗破苍穹手机版、斗破苍穹小说、斗破苍穹动画、斗破苍穹动漫、斗破苍穹电视剧、斗破苍穹电影',
        description: '《斗破苍穹：异火降世》预约开启，由玄幻网文巨作《斗破苍穹》原著小说和动画的正版IP双授权，完美还原了斗气大陆上的精彩故事和人物设定。不变的斗气大陆，全新的修炼体验，自动修炼享受斗气飞升的轻松畅快，战斗释手尽情欣赏华丽的视效演出，这一次，书写属于你的斗帝征程！ '
      }
    },
    {
      path: "/m",
      component: () => import("./views/yy/index.vue"),
      meta: {
        title: '《斗破苍穹：异火降世》预约官网-斗破苍穹正版飞空炫技手游',
        keywords: '斗破苍穹、萧炎、萧薰儿、海波东、巅峰对决、美杜莎、斗帝、斗气、斗破苍穹预约、斗破苍穹手游、斗破苍穹官网、斗破苍穹、斗破苍穹手游、古族、职业、玄幻、土豆、天蚕土豆、斗破苍穹手游、斗破手游、斗气之路、斗破苍穹、斗气之路手游、斗破苍穹手游版、斗破苍穹游戏、斗破苍穹ol、斗破苍穹手机版、斗破苍穹小说、斗破苍穹动画、斗破苍穹动漫、斗破苍穹电视剧、斗破苍穹电影',
        description: '《斗破苍穹：异火降世》预约开启，由玄幻网文巨作《斗破苍穹》原著小说和动画的正版IP双授权，完美还原了斗气大陆上的精彩故事和人物设定。不变的斗气大陆，全新的修炼体验，自动修炼享受斗气飞升的轻松畅快，战斗释手尽情欣赏华丽的视效演出，这一次，书写属于你的斗帝征程！ '
      }
    },
    // 预约官网
    {
      path: "/home",
      component: () => import("./views/yygw/index.vue"),
      meta: {
        title: '《斗破苍穹：异火降世》预约官网-斗破苍穹正版飞空炫技手游',
        keywords: '斗破苍穹、萧炎、萧薰儿、海波东、巅峰对决、美杜莎、斗帝、斗气、斗破苍穹预约、斗破苍穹手游、斗破苍穹官网、斗破苍穹、斗破苍穹手游、古族、职业、玄幻、土豆、天蚕土豆、斗破苍穹手游、斗破手游、斗气之路、斗破苍穹、斗气之路手游、斗破苍穹手游版、斗破苍穹游戏、斗破苍穹ol、斗破苍穹手机版、斗破苍穹小说、斗破苍穹动画、斗破苍穹动漫、斗破苍穹电视剧、斗破苍穹电影',
        description: '《斗破苍穹：异火降世》预约开启，由玄幻网文巨作《斗破苍穹》原著小说和动画的正版IP双授权，完美还原了斗气大陆上的精彩故事和人物设定。不变的斗气大陆，全新的修炼体验，自动修炼享受斗气飞升的轻松畅快，战斗释手尽情欣赏华丽的视效演出，这一次，书写属于你的斗帝征程！ '
      }
    },
    {
      path: "/home/m",
      component: () => import("./views/yygw/index.vue"),
      meta: {
        title: '《斗破苍穹：异火降世》预约官网-斗破苍穹正版飞空炫技手游',
        keywords: '斗破苍穹、萧炎、萧薰儿、海波东、巅峰对决、美杜莎、斗帝、斗气、斗破苍穹预约、斗破苍穹手游、斗破苍穹官网、斗破苍穹、斗破苍穹手游、古族、职业、玄幻、土豆、天蚕土豆、斗破苍穹手游、斗破手游、斗气之路、斗破苍穹、斗气之路手游、斗破苍穹手游版、斗破苍穹游戏、斗破苍穹ol、斗破苍穹手机版、斗破苍穹小说、斗破苍穹动画、斗破苍穹动漫、斗破苍穹电视剧、斗破苍穹电影',
        description: '《斗破苍穹：异火降世》预约开启，由玄幻网文巨作《斗破苍穹》原著小说和动画的正版IP双授权，完美还原了斗气大陆上的精彩故事和人物设定。不变的斗气大陆，全新的修炼体验，自动修炼享受斗气飞升的轻松畅快，战斗释手尽情欣赏华丽的视效演出，这一次，书写属于你的斗帝征程！ '
      }
    },
    {
      path: "/test",
      component: () => import("./views/test.vue"),
      meta: {
        title: '《斗破苍穹：异火降世》预约官网-斗破苍穹正版飞空炫技手游',
        keywords: '斗破苍穹、萧炎、萧薰儿、海波东、巅峰对决、美杜莎、斗帝、斗气、斗破苍穹预约、斗破苍穹手游、斗破苍穹官网、斗破苍穹、斗破苍穹手游、古族、职业、玄幻、土豆、天蚕土豆、斗破苍穹手游、斗破手游、斗气之路、斗破苍穹、斗气之路手游、斗破苍穹手游版、斗破苍穹游戏、斗破苍穹ol、斗破苍穹手机版、斗破苍穹小说、斗破苍穹动画、斗破苍穹动漫、斗破苍穹电视剧、斗破苍穹电影',
        description: '《斗破苍穹：异火降世》预约开启，由玄幻网文巨作《斗破苍穹》原著小说和动画的正版IP双授权，完美还原了斗气大陆上的精彩故事和人物设定。不变的斗气大陆，全新的修炼体验，自动修炼享受斗气飞升的轻松畅快，战斗释手尽情欣赏华丽的视效演出，这一次，书写属于你的斗帝征程！ '
      }
    }, {
      path: "/role",
      component: () => import("./views/yygw/com/reward.vue"),
      meta: {
        title: '《斗破苍穹：异火降世》预约官网-斗破苍穹正版飞空炫技手游',
        keywords: '斗破苍穹、萧炎、萧薰儿、海波东、巅峰对决、美杜莎、斗帝、斗气、斗破苍穹预约、斗破苍穹手游、斗破苍穹官网、斗破苍穹、斗破苍穹手游、古族、职业、玄幻、土豆、天蚕土豆、斗破苍穹手游、斗破手游、斗气之路、斗破苍穹、斗气之路手游、斗破苍穹手游版、斗破苍穹游戏、斗破苍穹ol、斗破苍穹手机版、斗破苍穹小说、斗破苍穹动画、斗破苍穹动漫、斗破苍穹电视剧、斗破苍穹电影',
        description: '《斗破苍穹：异火降世》预约开启，由玄幻网文巨作《斗破苍穹》原著小说和动画的正版IP双授权，完美还原了斗气大陆上的精彩故事和人物设定。不变的斗气大陆，全新的修炼体验，自动修炼享受斗气飞升的轻松畅快，战斗释手尽情欣赏华丽的视效演出，这一次，书写属于你的斗帝征程！ '
      }
    },
  ],
});

router.beforeEach((to, from, next) => {
  // 路由发生变化改变description和keyword
  ReviseMeta(to.meta.title, to.meta.keywords, to.meta.description)
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);

  if (isMobile) {
    if (to.path == "/") {
      next({ path: "/m", query: to.query });
    } else if (to.path == "/home") {
      next({ path: "/home/m", query: to.query });
    } else {
      next();
    }
  } else {
    if (to.path == "/m") {
      next({ path: "/", query: to.query });
    } else if (to.path == "/home/m") {
      next({ path: "/home", query: to.query });
    } else {
      next();
    }
  }
  // next()
});

export default router;
