// https://element-plus.org/zh-CN/
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// rem换算
import '@/util/rem'

// 日期和时间本地化
import 'dayjs/locale/zh-cn'

// https://animate.style/
import 'animate.css';

import '../../assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

const app = createApp(App)

// 调试器
if (import.meta.env.DEV) {
    import('vconsole').then(({ default: VConsole }) => {
        new VConsole();
    });
}

app.use(router)
app.use(ElementPlus)
app.mount('#app')
