export const ReviseMeta = (title, keywords, description) => {
    const head = document.getElementsByTagName("head");

    // 修改 <title> 标签内容
    if (title) {
        document.title = title;
    }

    // 创建并修改 <meta> 标签
    const meta = document.createElement("meta");
    if (keywords) {
        document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content", keywords);
    }
    if (description) {
        document
            .querySelector('meta[name="description"]')
            .setAttribute("content", description);
    }
    head[0].appendChild(meta);

}