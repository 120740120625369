(function (win, doc) {
    if (!win.addEventListener) return;
    function setFont() {
        var html = document.documentElement;
        var k = ''
        if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
            k = 750;
        } else {
            k = 1920;
        }
        html.style.fontSize = html.clientWidth / k * 100 + "px";
    }
    doc.addEventListener('DOMContentLoaded', setFont, false);
    win.addEventListener('resize', setFont, false);
    win.addEventListener('load', setFont, false);
})(window, document);